import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";


let globalContactSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getContactSchema() {
        if (globalContactSchema) {
            return globalContactSchema;
        }
        const response = await axiosWithETAGCache.get(`/contact/schema`);
        globalContactSchema = response.data;
        return response.data;
    },
    async getContacts(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axiosWithETAGCache.get(`/contact`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewContact() {
        const newContactData = {}

        const response = await axios.post(`/contact`, newContactData);

        return response.data;
    },
    async getContact(contactId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/contact/${contactId}`, {
            params: queryParams
        });

        return response.data;
    },
    async saveContact(contact) {
        const response = await axios.put(`/contact/${contact.id}`, contact);
        return response.data;
    },
    async deleteContact(contactId) {
        const response = await axios.delete(`/contact/${contactId}`);

        return response.data;
    },
    async forceFollowUpWithContact(contactId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.post(`/contact/${contactId}/force_follow_up`, {
            params: queryParams
        });

        return response.data;
    },
    async getPersonalInfo() {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/personal_info`, {
            params: queryParams
        });

        return response.data;
    },
    async savePersonalInfo(businessInfo) {
        const response = await axios.put(`/personal_info`, businessInfo);
        return response.data;
    },
}

export default api;
