import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";


let globalContentSampleSchema = null;
let globalTopicExtractorPromptSchema = null;
let globalTranscriptProcessorConfigurationSchema = null;
let globalGeneratedContentSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getContentSampleSchema() {
        if (globalContentSampleSchema) {
            return globalContentSampleSchema;
        }
        const response = await axiosWithETAGCache.get(`/content_sample/schema`);
        globalContentSampleSchema = response.data;
        return response.data;
    },
    async getContentSamples(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axiosWithETAGCache.get(`/content_sample`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewContentSample(newContentSampleData) {
        const response = await axios.post(`/content_sample`, newContentSampleData);

        return response.data;
    },
    async getContentSample(content_sampleId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/content_sample/${content_sampleId}`, {
            params: queryParams
        });

        return response.data;
    },
    async saveContentSample(content_sample) {
        const response = await axios.put(`/content_sample/${content_sample.id}`, content_sample);
        return response.data;
    },
    async deleteContentSample(content_sampleId) {
        const response = await axios.delete(`/content_sample/${content_sampleId}`);

        return response.data;
    },
    async getTopicExtractorPromptSchema() {
        if (globalTopicExtractorPromptSchema) {
            return globalTopicExtractorPromptSchema;
        }
        const response = await axiosWithETAGCache.get(`/topic_extractor_prompt/schema`);
        globalTopicExtractorPromptSchema = response.data;
        return response.data;
    },
    async getTopicExtractorPrompts(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axiosWithETAGCache.get(`/topic_extractor_prompt`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewTopicExtractorPrompt(newTopicExtractorPromptData) {
        const response = await axios.post(`/topic_extractor_prompt`, newTopicExtractorPromptData);

        return response.data;
    },
    async getTopicExtractorPrompt(topic_extractor_promptId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/topic_extractor_prompt/${topic_extractor_promptId}`, {
            params: queryParams
        });

        return response.data;
    },
    async saveTopicExtractorPrompt(topic_extractor_prompt) {
        const response = await axios.put(`/topic_extractor_prompt/${topic_extractor_prompt.id}`, topic_extractor_prompt);
        return response.data;
    },
    async deleteTopicExtractorPrompt(topic_extractor_promptId) {
        const response = await axios.delete(`/topic_extractor_prompt/${topic_extractor_promptId}`);

        return response.data;
    },
    async getTranscriptProcessorConfigurationSchema() {
        if (globalTranscriptProcessorConfigurationSchema) {
            return globalTranscriptProcessorConfigurationSchema;
        }
            const response = await axiosWithETAGCache.get(`/transcript_processor_configuration/schema`);
        globalTranscriptProcessorConfigurationSchema = response.data;
        return response.data;
    },
    async getTranscriptProcessorConfigurations(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axiosWithETAGCache.get(`/transcript_processor_configuration`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewTranscriptProcessorConfiguration(newTranscriptProcessorConfigurationData) {
        const response = await axios.post(`/transcript_processor_configuration`, newTranscriptProcessorConfigurationData);

        return response.data;
    },
    async getTranscriptProcessorConfiguration(transcriptProcessorConfigurationId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/transcript_processor_configuration/${transcriptProcessorConfigurationId}`, {
            params: queryParams
        });

        return response.data;
    },
    async saveTranscriptProcessorConfiguration(transcript_processor_configuration) {
        const response = await axios.put(`/transcript_processor_configuration/${transcript_processor_configuration.id}`, transcript_processor_configuration);
        return response.data;
    },
    async deleteTranscriptProcessorConfiguration(transcriptProcessorConfigurationId) {
        const response = await axios.delete(`/transcript_processor_configuration/${transcriptProcessorConfigurationId}`);

        return response.data;
    },
    async processTranscript(transcript, transcriptProcessorConfigurationId) {
        const data = {
            transcript: transcript ?? ""
        }

        const response = await axios.post(`/transcript_processor_configuration/${transcriptProcessorConfigurationId}/process_transcript`, data);
        return response.data;
    },
    async getGeneratedContentSchema() {
        if (globalGeneratedContentSchema) {
            return globalGeneratedContentSchema;
        }
        const response = await axiosWithETAGCache.get(`/generated_content/schema`);
        globalGeneratedContentSchema = response.data;
        return response.data;
    },
    async getGeneratedContents(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axiosWithETAGCache.get(`/generated_content`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewGeneratedContent(newGeneratedContentData) {
        const response = await axios.post(`/generated_content`, newGeneratedContentData);

        return response.data;
    },
    async getGeneratedContent(generatedContentId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/generated_content/${generatedContentId}`, {
            params: queryParams
        });

        return response.data;
    },
    async saveGeneratedContent(generated_content) {
        const response = await axios.put(`/generated_content/${generated_content.id}`, generated_content);
        return response.data;
    },
    async deleteGeneratedContent(generatedContentId) {
        const response = await axios.delete(`/generated_content/${generatedContentId}`);

        return response.data;
    },
}

export default api;
