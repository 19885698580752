import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";


let globalTenantConfigurationSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getTenantConfigurationSchema() {
        if (globalTenantConfigurationSchema) {
            return globalTenantConfigurationSchema;
        }
        const response = await axiosWithETAGCache.get(`/tenant_configuration/schema`);
        globalTenantConfigurationSchema = response.data;
        return response.data;
    },
    async getTenantConfigurations(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axiosWithETAGCache.get(`/tenant_configuration`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewTenantConfiguration(configData) {
        const response = await axios.post(`/tenant_configuration`, configData);

        return response.data;
    },
    async getTenantConfiguration(tenantConfigurationId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/tenant_configuration/${tenantConfigurationId}`, {
            params: queryParams
        });

        return response.data;
    },
    async saveTenantConfiguration(tenantConfiguration) {
        const response = await axios.put(`/tenant_configuration/${tenantConfiguration.id}`, tenantConfiguration);
        return response.data;
    },
    async deleteTenantConfiguration(tenantConfigurationId) {
        const response = await axios.delete(`/tenant_configuration/${tenantConfigurationId}`);

        return response.data;
    },
}

export default api;
