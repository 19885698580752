import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

let globalIntakeSchema = null;

const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getIntakeSurveySchema() {
    if (globalIntakeSchema) {
      return globalIntakeSchema;
    }
    const response = await axiosWithETAGCache.get(`/intake_survey/schema`);
    globalIntakeSchema = response.data;
    return response.data;
  },

  async getIntakeSurveys(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/intake_survey`, {
      params: queryParams,
    });

    return response.data;
  },

  async createNewIntakeSurvey() {
    const newIntakeSurvey = {};

    const response = await axios.post(`/intake_survey`, newIntakeSurvey);

    return response.data;
  },

  async getIntakeSurvey(intakeSurveyId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/intake_survey/${intakeSurveyId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },

  async updateIntakeSurvey(intakeSurvey) {
    const response = await axios.put(
      `/intake_survey/${intakeSurvey.id}`,
      intakeSurvey
    );
    return response.data;
  },

  async deleteIntakeSurvey(intakeSurveyId) {
    const response = await axios.delete(`/intake_survey/${intakeSurveyId}`);

    return response.data;
  },

  async startIntakeChatConversation(data) {
    const response = await axios.post(`/intake_chat/conversation`, data);

    return response.data;
  },
};

export default api;
